
.custom-control-label {
    // Background-color and (when enabled) gradient
    &::before {
        border: $custom-control-indicator-border-width solid $custom-control-indicator-border-color;
        @if $enable-transitions {
            transition: all .3s ease;;
        }
    }

    span {
        position: relative;
        top: 2px;
    }
}

.custom-control {
  padding-left: 1.7rem !important;
}

.custom-control-label {
  margin-bottom: 0;

  &:before,
  &:after {
    left: -1.75rem;
  }
}

.custom-control-input {
    &:active~.custom-control-label::before {
        border-color: $custom-control-indicator-active-border-color;
    }
}

// Alternative custom control
.custom-control-alternative {
    .custom-control-label {
        // Background-color and (when enabled) gradient
        &::before {
            border: 0;
            box-shadow: $input-alternative-box-shadow;
        }
    }

    .custom-control-input {
        &:checked {
            ~ .custom-control-label {
                &::before {
                    box-shadow: $input-focus-alternative-box-shadow;
                }
            }
        }

        &:active~.custom-control-label::before,
        &:focus~.custom-control-label::before {
            box-shadow: $input-alternative-box-shadow;
        }
    }
}


// Checkboxes
.custom-checkbox {
    .custom-control-input~.custom-control-label {
        cursor: pointer;
        font-size: $font-size-sm;
    }

    .custom-control-input {
        &:checked {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-checked-border-color;
                }
                &::after {
                    background-image: $custom-checkbox-indicator-icon-checked;
                }
            }
        }

        &:disabled {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-disabled-bg;
                }
            }

            &:checked {
                &::before {
                    border-color: $custom-control-indicator-checked-disabled-bg;
                }
            }
        }
    }
}

// Radios
.custom-radio {
    .custom-control-input~.custom-control-label {
        cursor: pointer;
        font-size: $font-size-sm;
    }

    .custom-control-input {
        &:checked {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-checked-border-color;
                }
                &::after {
                    background-image: $custom-radio-indicator-icon-checked;
                }
            }
        }

        &:disabled {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-disabled-bg;
                }
            }

            &:checked {
                &::before {
                    border-color: $custom-control-indicator-checked-disabled-bg;
                }
            }
        }
    }

}

// Toggles
.custom-toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 1.5rem;

    input {
        display: none;

        &:checked {
             + .custom-toggle-slider {
                border: $custom-control-indicator-border-width solid $custom-control-indicator-checked-border-color;

                &:before {
                    background: $custom-toggle-checked-bg;
                    transform: translateX(1.625rem);
                }
            }
        }

        &:disabled {
            + .custom-toggle-slider {
                border: $custom-control-indicator-border-width solid $custom-control-indicator-disabled-bg;
            }

            &:checked {
                + .custom-toggle-slider {
                    border: $custom-control-indicator-border-width solid $custom-control-indicator-disabled-bg;

                    &:before {
                        background-color: lighten($custom-control-indicator-checked-bg, 10%);
                    }
                }
            }
        }
    }
}

.custom-toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: $custom-control-indicator-border-width solid $input-border-color;
    border-radius: 34px !important;
    background-color: transparent;


    &:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 2px;
        bottom: 2px;
        border-radius: 50% !important;
        background-color: $custom-toggle-slider-bg;
        transition: $input-transition;
    }
}
